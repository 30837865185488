import Cookies from "js-cookie";
import "./auth.css";

const pathApi = "https://apiv2-6yhl3kiv2a-uc.a.run.app";

const fetchProtectedData = async () => {
  try {
    const response = await fetch(
      pathApi +
        "/protected?sessionCookie=" +
        Cookies.get("session") +
        "&url=" +
        window.location.origin +
        "&date=" +
        Date.now() +
        "&auth=" +
        true,
      {
        method: "GET",
        credentials: "include", // Inclure les cookies dans la requête
      }
    );

    if (!response.ok) {
      const params = new URLSearchParams(window.location.search);
      const session = params.get("session");

      if (session) {
        Cookies.set("session", session, {
          expires: 7,
          domain: window.location.auth, // Cookie spécifique à ce domaine
        });
        window.location.href = "/"; // Nettoyer l'URL après avoir enregistré le cookie
        return true;
      }
    } else {
      const data = await response.json(); // Récupérer les données de la réponse
      return data;
    }
  } catch (error) {
    console.error("Error fetching protected data:", error);
  }
};

export { fetchProtectedData };
