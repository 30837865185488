import React, { useEffect, useState } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import "firebase/compat/firestore";

// css
import "./App.css";
import "./assets/css/reset.css";
import "./assets/css/root.css";

// components
import Home from "./pages/home/index.jsx";
import UploadImage from "./pages/uploadImage";
import ManageImage from "./pages/manageImage";
import UploadQuote from "./pages/uploadQuote";
import ManageQuote from "./pages/manageQuote/manageQuote";
import { fetchProtectedData } from "./components/auth/index.jsx";
import Footer from "./components/footer/index.jsx";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProtectedData().then((data) => {
      if (data) {
        setLoading(false);
      } else {
        window.location.href =
          "//auth.loukaaa.com/signin?url=" + window.location.origin;
      }
    });
  }, []);

  const router = createBrowserRouter([
    {
      // errorElement: <ErrorPage />,
      path: "/",
      element: (
        <>
          <Outlet />
          <Footer />
        </>
      ),
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/upload-image",
          element: <UploadImage />,
        },
        {
          path: "/manage-image",
          element: <ManageImage />,
        },
        {
          path: "/upload-quote",
          element: <UploadQuote />,
        },
        {
          path: "/manage-quote",
          element: <ManageQuote />,
        },
        {
          path: "/upload-quote",
          element: <UploadQuote />,
        },
        {
          path: "/manage-quote",
          element: <ManageQuote />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      {loading ? (
        <>
          <div className="loading">
            <h2>admin.dd04photos.fr</h2>
          </div>
        </>
      ) : (
        // Affiche le contenu de l'application une fois les données chargées
        <RouterProvider router={router} />
      )}
    </div>
  );
}

export default App;
