import React from "react";
import "./home.css";
import { Link } from "react-router-dom";

// image
import Profile from "../../assets/images/profile.jpg";

// icons
import { BsFillCircleFill, BsChatQuote } from "react-icons/bs";
import { SlArrowRight } from "react-icons/sl";
import { TbEditCircle, TbCloudUpload } from "react-icons/tb";

function Home() {
  return (
    <div className="Home">
      <div className="header">
        <div className="title text-6xl">Administration</div>
      </div>
      <div className="wrapper">
        <div className="status">
          <div className="left">
            <img src={Profile} alt="" />
            <div className="text">
              <div className="title">DD04 Photos</div>
              <div className="url">www.dd04photos.fr</div>
            </div>
          </div>
          <div className="right good">
            <BsFillCircleFill className="icon" /> Parfait
          </div>
        </div>
        <div className="group">
          <div className="title-header">PHOTOS</div>
          <Link to="/upload-image" className="upload-image container">
            <div className="left">
              <div className="icon">
                <TbCloudUpload className="icon" />
              </div>
              <div className="ctn-text">
                <div className="title">Ajouter une ou plusieurs images</div>
                <div className="text">
                  Vos images seront compréssé et signé et seront automatiquement
                  publié
                </div>
              </div>
            </div>
            <div className="right">
              <SlArrowRight className="icon" />
            </div>
          </Link>
          <Link to="/manage-image" className="manage-image container">
            <div className="left">
              <div className="icon">
                <TbEditCircle className="icon" />
              </div>
              <div className="ctn-text">
                <div className="title">Modifier, supprimer vos images</div>
                <div className="text">
                  Ajouter une description ou des tags ou alors supprimer l'image
                </div>
              </div>
            </div>
            <div className="right">
              <SlArrowRight className="icon" />
            </div>
          </Link>
        </div>
        <div className="group">
          <div className="title-header">CITATION</div>
          <Link to="/upload-quote" className="upload-quote container">
            <div className="left">
              <div className="icon">
                <BsChatQuote className="icon" />
              </div>
              <div className="ctn-text">
                <div className="title">Citation de l'image</div>
                <div className="text">
                  Décrire l'image en ajoutant une citation
                </div>
              </div>
            </div>
            <div className="right">
              <SlArrowRight className="icon" />
            </div>
          </Link>
          <Link to="/manage-quote" className="manage-quote container">
            <div className="left">
              <div className="icon">
                <TbEditCircle className="icon" />
              </div>
              <div className="ctn-text">
                <div className="title">Gérer les citations</div>
                <div className="text">
                  Changer ou supprimer la citation à l'aide d'un tableau
                </div>
              </div>
            </div>
            <div className="right">
              <SlArrowRight className="icon" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
