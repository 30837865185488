import React from "react";
import "./footer.css";

function Footer() {
  return (
    <footer>
      <p>
        Powered by <a href="//loukaaa.com">loukaaa.com</a>
      </p>
    </footer>
  );
}

export default Footer;
