import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./uploadImage.css";
import NavBar from "../../components/bar/navBar";
import ButtonComponent from "../../components/button";
import { app } from "../../components/firebase/firebase";
import imageCompression from "browser-image-compression";
import "firebase/compat/storage";
import Notification from "../../components/notification";
import AudioSource from "../../assets/sound/check.mp3";

const MAX_IMAGE_SIZE = 400 * 1024; // 250 KB in bytes
const MAX_COMPRESSED_SIZE = 350 * 1024; // 200 KB in bytes

function UploadImage() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState("none");
  const [notification, setNotification] = useState(false);

  const db = app.firestore();

  useEffect(() => {
    setNotification(false);
  }, []);

  const compressImage = async (file) => {
    if (file.size > MAX_IMAGE_SIZE) {
      try {
        const options = {
          maxSizeMB: (MAX_COMPRESSED_SIZE / (1024 * 1024)).toFixed(2),
          maxWidthOrHeight: 2080, // You can adjust the maximum width or height of the compressed image
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);

        return compressedFile;
      } catch (error) {
        console.error("Error while compressing the image:", error);
        return null;
      }
    } else {
      return file; // Return the original file without compression
    }
  };

  const onDrop = async (acceptedFiles) => {
    setImages([]);
    const compressedImages = [];
    for (const file of acceptedFiles) {
      const compressedImage = await compressImage(file);
      if (compressedImage) {
        compressedImages.push(compressedImage);
      }
    }
    // Process compressed images and update the state with image info and id
    const newImages = compressedImages.map((file, index) => ({
      id: index, // Set the id for each image
      url: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      tags: ["montagne", "montagne", "montagne", "montagne"],
    }));

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleChange = (value, id, tags) => {
    // Create a copy of the images array
    var updatedImages = [...images];

    // Change the value of tags in the first object
    updatedImages[id].tags[tags] = value;

    // Update the state with the modified array
    setImages(updatedImages);
  };

  const handleSubmit = () => {
    setLoading("loading");
    const storageRef = app.storage().ref();

    db.collection("info")
      .doc("image")
      .get()
      .then(async (data) => {
        var index = await data.data().number;
        var count = await data.data().count;
        var id = 0;
        for (const file of images) {
          const response = await fetch(file.url);
          const blob = await response.blob();
          const fileName = 10000 + parseInt(index) + id + ".jpg"; // Replace the filename with your desired filename
          const imageRef = storageRef.child("images/" + fileName);
          await imageRef.put(blob);
          await db
            .collection("image")
            .doc((10000 + parseInt(index) + id).toString())
            .set({
              date: Date.now(),
              tags: [
                images[id].tags[0],
                images[id].tags[1],
                images[id].tags[2],
                images[id].tags[3],
              ],
              exif: "0",
              id: index + id,
              index: 10000 + parseInt(index) + id,
              indexString: (10000 + parseInt(index) + id).toString(),
            })
            .then(() => {
              id++;
            });
        }
        await db
          .collection("info")
          .doc("image")
          .update({
            number: index + id,
          });
        await db
          .collection("info")
          .doc("image")
          .update({
            count: count + 1,
          })
          .then(() => {
            setLoading("none");
            setImages([]);
            setNotification(true);
            const audio = new Audio(AudioSource); // Remplacez le chemin par le chemin vers votre fichier audio
            audio.play();
            setTimeout(() => {
              setNotification(false);
            }, 3000);
          });
      });
  };
  return (
    <>
      <NavBar link="/" title="Administration" />
      <Notification state={notification} />
      <div className="UploadImage">
        <div className="header">
          <div className="title text-6xl">Ajouter des images</div>
        </div>
        <div className="wrapper">
          <div className="square">
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <p>
                Faites glisser et déposez des images ici, ou cliquez pour
                sélectionner.
              </p>
            </div>
          </div>
          <div className="result">
            {images.map((data, index) => (
              <div className="container" key={index}>
                <div className="left">
                  <img src={data.url} alt="" />
                  <div className="ctn-text">
                    <div className="title">{data.name}</div>
                    <div className="size">{data.size}</div>
                  </div>
                </div>
                <div className="right">
                  <select
                    onChange={(e) => handleChange(e.target.value, data.id, 0)}
                  >
                    <option className="default-tag" value="">
                      Tag 1
                    </option>
                    <option value="Nuit">Nuit</option>
                    <option value="Coucher de soleil">Coucher de soleil</option>
                    <option value="Jours">Jours</option>
                    <option value="Levé du soleil">Levé du soleil</option>
                  </select>
                  <select
                    onChange={(e) => handleChange(e.target.value, data.id, 1)}
                  >
                    <option className="default-tag" value="">
                      Tag 2
                    </option>
                    <option value="Montagne">Montagne</option>
                    <option value="Ville">Ville</option>
                    <option value="Mer">Mer</option>
                    <option value="Plage">Plage</option>
                    <option value="Paysage">Paysage</option>
                    <option value="Macro">Macro</option>
                    <option value="Portrait">Portrait</option>
                    <option value="Artistique">Artistique</option>
                    <option value="Volcan">Volcan</option>
                    <option value="Animaux">Animaux</option>
                    <option value="Lavande">Lavande</option>
                    <option value="Orage">Orage</option>
                  </select>
                  <select
                    onChange={(e) => handleChange(e.target.value, data.id, 2)}
                  >
                    <option className="default-tag" value="">
                      Tag 3
                    </option>
                    <option value="Nuit">Nuit</option>
                    <option value="Coucher de soleil">Coucher de soleil</option>
                    <option value="Jours">Jours</option>
                    <option value="Levé du soleil">Levé du soleil</option>
                    <option value="Montagne">Montagne</option>
                    <option value="Ville">Ville</option>
                    <option value="Mer">Mer</option>
                    <option value="Plage">Plage</option>
                    <option value="Paysage">Paysage</option>
                    <option value="Macro">Macro</option>
                    <option value="Portrait">Portrait</option>
                    <option value="Artistique">Artistique</option>
                    <option value="Volcan">Volcan</option>
                    <option value="Animaux">Animaux</option>
                    <option value="Lavande">Lavande</option>
                    <option value="Orage">Orage</option>
                    <option value="Vue du ciel">Vue du ciel</option>
                  </select>
                  <input
                    value=""
                    onChange={(e) => handleChange(e.target.value, data.id, 3)}
                    placeholder="Tag 4"
                  />
                </div>
              </div>
            ))}
            {images[0] !== undefined && (
              <div className="ctn-button">
                <ButtonComponent
                  text="Envoyer"
                  onClick={() => handleSubmit()}
                  state={loading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadImage;
